import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { GeoFragmentFragmentDoc } from 'graphql/geo/Geo.generated';
import { PriceFragmentFragmentDoc } from 'graphql/price/Price.generated';
import { GroupOfProductFeatureItemsFragmentDoc } from 'graphql/productFeature/GroupOfProductFeatureItems.generated';
import { WishlistItemFragmentDoc } from 'graphql/wishlist/WishlistItemFragment.generated';
export type PageFragmentFragment = {
  __typename?: 'Page';
  pageNumber: number;
  resultsTotal?: number | null;
  resultsPerPage?: number | null;
};

export type GeoItemFragment = {
  __typename?: 'SrlGeoItem';
  count: number;
  country?: string | null;
  adults: number;
  children: number;
  averageTemp?: string | null;
  duration: number;
  disrupter?: string | null;
  flightDuration?: string | null;
  image: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  moreInfo?: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  } | null;
  geoObject: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
  perPersonPrice: { __typename?: 'Price'; amount: string; currency: string };
};

export type ProductItemFragment = {
  __typename?: 'SrlProductItem';
  giata: string;
  adults: number;
  children: number;
  hpRating?: number | null;
  taRating?: number | null;
  provider?: string | null;
  productCode?: string | null;
  type: Types.ProductType;
  distanceInfo?: string | null;
  duration: number;
  disrupter?: string | null;
  mealType: string;
  name: string;
  departureDate: string;
  returnDate: string;
  productClass: Types.ProductClass;
  fromTo?: string | null;
  offerId: string;
  inWishlist: boolean;
  transferIncluded?: boolean | null;
  taReviews?: number | null;
  image: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  geoBreadcrumbs: Array<{
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  }>;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  rooms?: Array<{
    __typename?: 'Room';
    boardType?: string | null;
    bookingCode?: string | null;
    roomType?: string | null;
  } | null> | null;
  featureSummary: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  price: { __typename?: 'Price'; amount: string; currency: string };
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  wishlistItem?: {
    __typename?: 'WishlistItem';
    id: string;
    inWishlist: boolean;
  } | null;
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export type SrlRoomFragment = {
  __typename?: 'Room';
  boardType?: string | null;
  bookingCode?: string | null;
  roomType?: string | null;
};

export type ShortProductItemFragment = {
  __typename?: 'SrlProductItem';
  offerId: string;
  inWishlist: boolean;
};

export const PageFragmentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'pageFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Page' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultsTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultsPerPage' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const GeoItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlGeoItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '294' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '147' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '298' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '152' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '596' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '304' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'children' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moreInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageTemp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geoFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'disrupter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightDuration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perPersonPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...GeoFragmentFragmentDoc.definitions,
    ...PriceFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const SrlRoomFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlRoom' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Room' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'boardType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomType' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const ProductItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlProductItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'giata' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '294' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '147' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '640' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '430' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '640' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '430' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoBreadcrumbs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'children' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hpRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taRating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlRoom' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distanceInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'disrupter' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mealType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inWishlist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlistItem' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taReviews' } },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...SrlRoomFragmentDoc.definitions,
    ...GroupOfProductFeatureItemsFragmentDoc.definitions,
    ...PriceFragmentFragmentDoc.definitions,
    ...WishlistItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const ShortProductItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'shortProductItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlProductItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'offerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inWishlist' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
